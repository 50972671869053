import logo from './orbit.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className='orbits'>
        <img id='ring0' src={logo} className="orbit-ring" alt="ring0" />
        <img id='ring1' src={logo} className="orbit-ring-reverse" alt="ring1" />
        <img id='ring2' src={logo} className="orbit-ring" alt="ring2" />
        <img id='ring3' src={logo} className="orbit-ring-reverse" alt="ring3" />
        </div>
        <p>
          Ethan Grissom
        </p>
      </header>
    </div>
  );
}

export default App;
